.add-contact-form {
  max-width: 800px;
}

.add-contact-group-form {
  max-width: 600px;
}

.add-contact-attr-form {
  max-width: 400px;
}

.csv-column-mapping {
  min-width: 170px;
}

.contacts-import-settings-form {
  max-width: 800px;
}

.actions-and-search {
  min-width: 500px;
}

.contact-panel {
  width: 800px !important;
}
