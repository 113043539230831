/*
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/alerts";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";;
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/repeater";
@import "custom/plugins/form-editors";
@import "custom/plugins/xeditable";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/datatable";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

.without-outline {
  outline: none !important;
  box-shadow: none !important;
}

.without-margin {
  margin: 0 !important;
}

.without-padding {
  padding: 0 !important;
}

.width-min-content {
  min-width: min-content;
}

.width-max-content {
  max-width: max-content;
}

.dotted-line-text {
  text-decoration-line: underline;
  text-decoration-color:  #80868b;
  text-decoration-style: dotted;
  //text-decoration: underline dotted #80868b; does not work in Safari
  text-underline-position: under;
  text-underline-offset: 1px;
}

.dashed-line-text {
  text-decoration: underline dashed #80868b;
  text-underline-position: under;
  text-underline-offset: 1px;
}

.dashboard-report-link:hover {
  background-color: rgba(85, 109, 230, 0.074);
}

.empty-card {
  min-height: 272px;
}